$white: #fff;
$black: #000;

$gray-primary: #333;
$gray-secondary: #58595b;
$gray-light: #d8d8d8;
$tert-gray: #f6f6f6;
$red: #cc0303;

$pink-primary: #e407eb;
$pink-secondary: #92278e;
// Grid
$grid-max-width: 980px;
$grid-wide: 1440px;
$modal-max-width: 800px;

$form-width: 260px;
