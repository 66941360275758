@import './common/breakpoints';
@import './common/variables';
@import './common/layout';
@import './common/mixins';
@import './common/reset';

.background-image {
  background-size: cover;
  background-position: center center;
  background-image: url('../assets/background-min.jpeg');
  background-repeat: no-repeat;
  scale: 1.1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 700px;
  z-index: -1; /* Ensure the background is behind other content */

  @include large-up {
    background-image: url('../assets/backgroundBig.jpeg');
  }
}

.background-overlay {
  background-color: $gray-primary;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.app {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  z-index: 1; /* Ensure content is above the background */
}

.languages-wrapper {
  position: absolute;
  top: 20px;
  right: 0;
  padding-right: 20px;
  display: flex;
  justify-content: end;
  color: $gray-light;
  font-size: calc(8px + 1vmin);

  width: 100%;
  z-index: 20;

  .languages {
    display: flex;
    column-gap: 10px;
    align-items: center;

    .language:hover {
      color: $pink-secondary;
      cursor: pointer;
    }
  }

  .separator {
    background-color: $gray-light;
    width: 2px;
    height: 16px;

    :hover {
      color: $gray-light !important;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 50px 0;
}

.subtext {
  font-size: calc(8px + 1vmin);
  color: $gray-light;
  width: 260px;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: $form-width;
  margin-top: 15px;

  &Group {
    display: flex;
    flex-direction: column;
    margin: 10px auto;
    width: 100%;
  }

  .venue {
    padding-top: 30px;
  }

  label {
    font-size: calc(8px + 1vmin);
    color: $gray-light;
    text-align: left;
    margin-bottom: 10px;
    padding-left: 5px;
    text-transform: uppercase;
  }

  input,
  select {
    width: $form-width;
    height: 56px;
    border-radius: 16px;
    border: 0px;
    background-color: rgba($gray-light, 0.6);
    padding: 0 16px;
    color: $white;
    font-size: calc(10px + 1vmin);

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @include medium-up {
      height: 56px;
    }
  }

  option {
    color: $gray-light;
    background-color: $gray-primary;

    &:checked {
      background-color: $pink-secondary;
    }
  }

  input:focus {
    border-color: $pink-secondary;
    box-shadow: 0 0 0 0.2rem rgba($pink-secondary, 0.25);
    outline: none;
  }

  input::placeholder {
    color: rgba($gray-light, 0.8);
    font-style: italic;
  }

  .button {
    width: $form-width;
    height: 56px;
    border-radius: 16px;
    border: 0px;
    background-color: rgba($pink-primary, 0.8);
    color: $white;
    font-size: calc(10px + 1vmin);
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba($pink-secondary, 0.8);
    }
  }
}

.footerText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1vmin);
  color: $gray-light;
  width: 260px;
  height: 60px;
  font-style: italic;
}

.socialLinksWrapper {
  display: flex;
  align-items: flex-end;
  // justify-self: flex-end;

  .socialLink {
    width: 32px;
    height: 32px;
    margin: 16px;
    color: $white;
    display: flex;
    opacity: 0.7;

    &:hover {
      opacity: 0.5;
    }

    svg {
      fill: $white;
      height: auto;

      path {
        fill: rgba($white, 0.7);
      }
    }
  }
}

.rmdp-container {
  padding-right: 16px !important;
}

.rmdp-week-day {
  color: $black !important;
}

.rmdp-arrow-container {
  padding-top: 2px !important;
}

.nonworking-day {
  text-decoration: line-through;
}

.rmdp-day.rmdp-selected > .highlight-red {
  background-color: #ea0034 !important;
}

.calendarText {
  padding: 8px 0;
  color: $red !important;
  font-size: calc(8px + 1vmin);

  @include large-up {
    font-size: calc(4px + 1vmin);
  }
}
